<template>
  <div>
    <a-button @click="$router.push('/actuality')" type="primary">
      {{ $t("actualite.retour") }}
    </a-button>
    <a-card class="mt-2" :title="actuality.title">
      <div>
        <a-carousel v-if="actuality.photos && actuality.photos.length > 0" arrows dots-class="slick-dots slick-thumb">
          <a slot="customPaging" slot-scope="props">
            <img :src="getImgUrl(props.i)" />
          </a>
          <div v-for="photo in actuality.photos" :key="photo">
            <img :src="settings.base_url + '/' + photo" />
          </div>
        </a-carousel>
        <center v-else>{{ $t("actualite.pasImages") }}</center>
        <center>
          <a-button @click="() => (visibleModal = true)" type="primary" shape="circle" icon="edit" class="mt-5" />
        </center>
        <a-modal v-model="visibleModal" @cancel="handleCancel" :title="$t('actualite.modifierImages')" :width="500"
          :height="1200" :footer="false">
          <div v-for="photo in actuality.photos" :key="photo" class="my-2">
            <div v-if="!toSuppFiles.includes(photo)">
              <img class="minimized-image" :src="settings.base_url + '/' + photo" />
              <a-button class="ml-2" type="danger" shape="circle" icon="delete" @click="() => toSuppFiles.push(photo)">
              </a-button>
            </div>
          </div>
          <a-upload-dragger :file-list="photos" :before-upload="beforeUploadFiles" :custom-request="
            () => {
              return true;
            }
          ">
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">{{ $t("actualite.cliquer") }}</p>
            <p class="ant-upload-hint">
              {{ $t("actualite.support") }}
            </p>
          </a-upload-dragger>
          <div class="form-actions mt-0">
            <a-button style="width: 150px" type="primary" htmlType="submit" @click="update" class="mr-3">{{
              $t("action.modifier") }}</a-button>
            <a-button key="back" @click="handleCancelModal">
              {{ $t("action.annuler") }}
            </a-button>
          </div>
        </a-modal>
      </div>

      <a-divider />
      <div class="container h5">
        <div class="row my-2">
          <div class="col-4">
            <b>{{ $t("actualite.titre") }} :</b>
          </div>
          <div class="col-8" v-if="!edit">
            {{ actuality.title }}
          </div>
          <div class="col-8" v-else>
            <a-input :placeholder="$t('actualite.titre')" v-model="title" />
          </div>
        </div>
        <div class="row my-2">
          <div class="col-4">
            <b>{{ $t("actualite.categorie") }} :</b>
          </div>
          <div class="col-8" v-if="!edit">
            <a-tag color="purple">{{ actuality.category }}</a-tag>
          </div>
          <div class="col-8" v-else>
            <a-input v-model="category" />
          </div>
        </div>
        <div class="row my-2">
          <div class="col-4">
            <b>{{ $t("actualite.cibles") }} :</b>
          </div>
          <div class="col-8" v-if="!edit || (actuality.students || actuality.levels || actuality.classrooms)">
            <span v-for="target in actuality.target" :key="target">
              <a-tag color="blue">{{ target }}</a-tag>
            </span>
          </div>
          <div class="col-8" v-else>
            <a-select mode="multiple" v-model="target">
              <a-select-option value="all">
                {{ $t("actualite.tous") }}
              </a-select-option>
              <a-select-option value="teacher">
                {{ $t("actualite.enseignants") }}
              </a-select-option>
              <a-select-option value="student">
                {{ $t("actualite.eleves") }}
              </a-select-option>
              <a-select-option value="administration">
                {{ $t("actualite.administration") }}
              </a-select-option>
              <a-select-option value="parents">
                {{ $t("actualite.parents") }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="row my-2" v-if="actuality.students || actuality.levels || actuality.classrooms">
          <div class="col-4">
            <b>{{ $t("actualite.specificFor") }} :</b>
          </div>
          <div class="col-8">
            <span v-if="actuality.students">
              <a-tag v-for="student in actuality.students" :key="student" color="green">{{ getStudentFullname(student)
              }}</a-tag>
            </span>
            <span v-else-if="actuality.classrooms">
              <a-tag v-for="classroom in actuality.classrooms" :key="classroom" color="green">{{
                getClassroomName(classroom)
              }}</a-tag>
            </span>
            <span v-else-if="actuality.levels">
              <a-tag v-for="level in actuality.levels" :key="level" color="green">{{ $t('niveau.niveau' + level)
              }}</a-tag>
            </span>
          </div>
        </div>
        <div class="row my-2" v-if="!edit && actuality.likes">
          <div class="col-4">
            <b>{{ $t("actualite.mentionJaimes") }} :</b>
          </div>
          <div class="col-8">
            {{ actuality.likes.length }}
          </div>
        </div>
        <div class="row my-2">
          <div class="col-4">
            <b>{{ $t("actualite.description") }} :</b>
          </div>
          <div class="col-8" v-if="!edit" v-html="actuality.desc"></div>
          <div class="col-8" v-else>
            <quill-editor :content="actuality.desc" v-model="description" :options="editorOptions" />
          </div>
        </div>
      </div>
      <template slot="actions" class="ant-card-actions">
        <a-icon v-if="!edit" @click="() => (edit = true)" type="edit" />
        <a-icon v-if="edit" @click="updateNews" type="check" />
        <a-icon v-if="edit" @click="handleCancel" type="close" />
      </template>
    </a-card>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { mapState } from "vuex";

const baseUrl =
  "https://raw.githubusercontent.com/vueComponent/ant-design-vue/master/components/vc-slick/assets/img/react-slick/";

export default {
  components: {
    quillEditor,
  },
  computed: mapState(["settings"]),

  data: () => ({
    actuality: {},
    baseUrl,
    edit: false,
    description: "",
    title: "",
    category: "",
    target: [],
    visibleModal: false,
    photos: [],
    classes: [],
    studentList: [],
    toSuppFiles: [],
    editorOptions: {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],

          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],

          ['clean'],
        ],
      },
    },
  }),
  async created() {
    await this.getActuality();


    apiClient
      .post('/students/filter', {
        query: {
          status: 'active',
        },
      }).then(({ data }) => {
        this.studentList = data
      })

    apiClient
      .post('/classrooms/filter', {
        query: {
          status: 'active',
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data))
  },
  methods: {
    getStudentFullname(id) {
      const student = this.studentList.find((s) => s._id == id);
      if (student) return student.firstName + " " + student.lastName;
    },
    getClassroomName(id) {
      const classe = this.classes.find((s) => s._id == id);
      if (classe) return classe.name;
    },
    async getActuality() {
      await apiClient
        .post("/actuality/all/filter", {
          query: {
            _id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.actuality = res.data[0];
          this.title = this.actuality.title;
          this.category = this.actuality.category;
          this.target = this.actuality.target;
        })
        .catch((e) => this.$message.error(this.$t("error.aucActualite")));
    },
    getImgUrl(i) {
      return this.settings.base_url + "/" + this.actuality.photos[i];
    },
    updateNews(e) {
      let data = {};
      if (this.title) data.title = this.title;
      if (this.target.length > 0) data.target = this.target;
      if (this.category) data.category = this.category;
      data.description = this.description;
      apiClient.patch("/actuality/" + this.actuality._id, data).then((res) => {
        this.edit = false;
      });
    },
    handleCancel() {
      this.title = this.actuality.title;
      this.category = this.actuality.category;
      this.target = this.actuality.target;
      this.edit = false;
    },
    handleCancelModal() {
      this.visibleModal = false;
      this.photos = [];
      this.toSuppFiles = [];
    },
    beforeUploadFiles(file, _) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error(this.$t("error.fichiers"));
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t("error.image"));
      }

      const MaxPhotos = this.actuality.photos.length + this.photos.length < 15;
      if (!MaxPhotos) {
        this.$message.error(this.$t("error.maxImage"));
      }

      if (isJpgOrPng && isLt2M && MaxPhotos) {
        this.$message.success(
          this.$t("success.imageTelecharge", { name: file.name })
        );
        this.photos.push(file);
      }
      return isJpgOrPng && isLt2M;
    },
    async update() {
      if (this.toSuppFiles.length > 0)
        await this.suppFiles();
      if (this.photos.length > 0)
        await this.addFile();
      this.visibleModal = false;
      this.toSuppFiles = [];
      this.photos = [];
    },
    async addFile() {
      let formData = new FormData();
      for (const file of this.photos) formData.append("photos", file);

      for (var i = 0; i < this.actuality.photos.length; i++) {
        formData.append("oldPhotos[]", this.actuality.photos[i]);
      }

      await apiClient({
        method: "put",
        url: "/actuality/addPhotos/" + this.actuality._id,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          //res.data.createdAt = moment(this.res.data.createdAt).format("DD/MM/YYYY HH:mm")
          this.actuality.photos.push(...res.data.data.photos);
          this.$message.success(this.$t("success.photoAjout"));
        })
        .catch((e) => this.$message.error(this.$t("error.impoPubAjout")));
    },
    async suppFiles() {
      let photos = this.actuality.photos.filter((el) => !this.toSuppFiles.includes(el));
      await apiClient
        .patch("/actuality/" + this.actuality._id, { photos: photos })
        .then((res) => {
          this.actuality.photos = this.actuality.photos.filter(
            (el) => !this.toSuppFiles.includes(el)
          );
        });
    },
  },
};
</script>
<style scoped>
/* For demo */
.ant-carousel>>>.slick-dots {
  height: 0px;
}

.ant-carousel>>>.slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}

.ant-carousel>>>.slick-thumb {}

.ant-carousel>>>.slick-thumb li {
  width: 60px;
  height: 45px;
}

.ant-carousel>>>.slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

.ant-carousel>>>.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}

.ql-editor {
  height: 100px;
}

.minimized-image {
  height: 50px;
  width: 50px;
  object-fit: contain;
}
</style>
